import '../App.css';
import Bcihmct from '../assets/bcihmct.pdf';
import Bcip from '../assets/bcip.pdf';
import Bcips from '../assets/bcips.pdf';
import Nl from '../assets/e-newsletter2015janvol3issue1.jpg'
import BDPS from '../assets/Experience/BDPS.jpg'
import AL from '../assets/Experience/AppreciationLetter.jpg'
import IIC_AL from '../assets/Experience/IIC_Appreciation_Letter_AY_2022_23.pdf';
import Best1 from '../assets/Conferences/best1-bcips.pdf';
import Best2 from '../assets/Conferences/Best2-BCIIT.jpg';
import Best3 from '../assets/BestPaper-GDGU.pdf';
import MCASyllabus from '../assets/MCA Course-Wise Syllabus Development Committee dated 21-08-2020.pdf';
import QPSetter from '../assets/UnivExaminationQPSetting.pdf';
import AisheCertificate from '../assets/AISHE_certificate.pdf'
export function Achievements() {
      return (
            <div className="pd">
                   <table>
                        <tr><th colSpan={3} className='p-heading'> Best Paper Awards - for Paper Presentation in National and International Conferences    </th></tr>
                        <tr>
                        <td>1</td><td><a href={Best3} target='_Blank'>Best Paper Award for "Performance of Random Forest for Flash Flood susceptibility in Indian Central Himalayan Region" </a></td>
			      <td>6th International Conference GDGUICON on Driving Sustainability, Circularity and Development through Innovations in Management and Technology- G D Goenka University</td>
			      <td>April 2024</td></tr>
			      <tr><td>2</td><td><a href={Best2} target='_Blank'>Best Paper Award for “Performance of Machine Learning Algorithms for Multi-hazard Susceptibility Assessment in the Mountainous Regions: An Overview" </a></td>
			      <td> 1st International Conference on Advances in Computing, Communication and Intelligent Systems (ICACCIS), Jointly Organized by Banarsidas Chandiwala Institute of Information Technology (BCIIT) and 
				Universal Inovators, Delhi, India</td><td>April 2024</td></tr>
			      <tr><td>3</td><td><a href={Best1} target='_Blank'>Best Paper Award for "Voice Based Email System-A Solution to Digital Communication for Individuals with Visual Impairments" </a></td>
			      <td>11th National Conference on "Changing Global Landscape and New World of Work:Contours for Sustainable Business"</td><td>April 2024</td></tr>
                  </table>

                  <table>
                        <tr><th colSpan={3} className='p-heading'> Appreciation/Recognitions Letter/Syllabus Committee Member </th></tr>
                        <tr> <td>1</td><td><a href={MCASyllabus} target='_Blank'>MCA-Syllabus Committee Coodinator and Member</a></td><td>2020-21</td></tr>
                        <tr> <td>2</td><td><a href={QPSetter} target='_Blank'>UG/PG - Paper setter for the forthcoming End Term/Supplementary Examination</a></td><td>2020-21</td></tr>
                        <tr> <td>2</td><td><a href={AisheCertificate} target='_Blank'>AISHE - DCF Coordinator</a></td><td>2020-21</td></tr>
                  
                        <tr><td>3</td><td><a href={IIC_AL} target='_Blank'>Letter of Appreciation from MoE's Innovation Cell </a></td><td>2011</td></tr>
                        <tr><td>4</td><td><a href={AL} target='_Blank'>Letter of Appreciation for teaching methodology by Dr. S.K.Wadhwa(Director BCIIT) </a></td><td>2011</td></tr>
                        <tr> <td>5</td><td><a href={BDPS} target='_Blank'>Letter of Recognition for Training and Software Development by Mr. G. Prabagar (Director BDPS) </a></td><td>1996</td></tr>
                  
                  </table>
                  <table>
                        <tr><th colSpan={3} className='p-heading'> Inter-College Support (Chandiwala Group of Institutions)]   </th></tr>
                        <tr><td>1</td><td>Provided solution for securing BCIIT/<a href={Bcip} target="_blank" rel="noreferrer">BCIP</a>/<a href={Bcips} target="_blank" rel="noreferrer">BCIPS</a>/<a href={Bcihmct} target="_blank" rel="noreferrer">
                              BCIHMCT
                        </a>/Chandiwala Estate websites form Virus Attacks. Recovered Hacked Website without Anti-Virus.</td><td></td></tr>
                        <tr> <td>2</td><td>Provided solution by rewriting the obsolete API function call not supported by the current
                              database version BCIIT/<a href={Bcihmct} target="_blank" rel="noreferrer">BCIHMCT</a>/Chandiwala Estate websites. </td><td></td></tr>
                        <tr> <td>3</td><td>Created software solution of Eye-Hospital, Motihari, Bihar.</td><td></td></tr>
                  </table>
                
                  <p> </p>
                  <table>
                        <tr><th colSpan={3} className='p-heading'>Worked on following projects [Post Ph. D research]   </th></tr>
                        <tr> <td>1</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_10_ISSUE_4/IJCET_10_04_003.pdf' target='_Blank'>  Audio Steganography Technique using Circular Bit
                              Replacement </a> </td><td></td></tr>
                        <tr> <td>2</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_9_ISSUE_4/IJCET_09_04_017.pdf' target='_Blank'>Evaluation of Nth Bit Replacement in Audio
                              Steganography [Text]   </a></td><td></td></tr>
                        <tr> <td>3</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_8_ISSUE_5/IJCET_08_05_001.pdf' target='_Blank'> Performance Evaluation of Speaker Identification
                              System for Male Speaker During Adolescence </a> </td><td></td></tr>
                        
                  </table>
                  <p> </p>
                  <table>
                        <tr><th colSpan={3} className='p-heading'>Presently experimenting on following projects   </th></tr>

                        <tr> <td></td><td>Evaluation of speaker recognition system for identical twins where face-recognition is not
                        successful.</td><td></td></tr>
                         
                        <tr> <td></td><td>  Audio Steganography Technique using LSB Replacement [Audio]  </td><td></td></tr>
                        <tr> <td></td><td>  Audio Steganography Technique using Nth Bit Replacement [Audio] </td><td></td></tr>
                        <tr> <td></td><td>  Audio Steganography Technique using Circular Bit Replacement [Audio] </td><td></td></tr>
                  </table>
                  <p> </p>
                  <table>
                        <tr><th colSpan={3} className='p-heading'><a href='http://bciit.ac.in/images/stories/E-Magazine/Mag2013.pdf' target='_Blank' >Executive Editor- BCIIT EMagazine  2010 till 2014 and E-Newsletter 2013-14</a>  </th></tr>  
                        <tr><th colSpan={3} className='p-heading'><a href={Nl} target='_Blank'>Executive Editor- BCIIT E-Newsletter 2013 and 2015</a>  </th></tr>  
                  </table>
            </div>
      );
}
