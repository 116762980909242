import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Login from "./Login"
import Register from "./Register"
import { Route, BrowserRouter as Router, NavLink, Routes } from 'react-router-dom'
import sbPic from '../assets/sbPic.JPG';
import { PublicationJournal } from '../components/publications-journals';
import { Achievements } from '../components/achievements';
// import {HeaderText} from '../components/header-text';
import { EmploymentDetails } from '../components/experience';
import { Reviewer } from '../components/reviewer';
import { FdpStc } from '../components/fdp-stc';
import { AcademicDetails } from '../components/academic';
import { AdditionalResponsibilities } from '../components/current-job-role';
import Welcome from '../assets/HomeImage.png'
import { ActivitiesCoordinated } from '../components/activities-organized';
import CV from '../assets/cvUpdated.pdf';

function Home() {
    return (<img className='homeimg' src={Welcome} alt=''></img>);
}

function Dashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState({})

    useEffect(() => {
        if (localStorage.getItem('token') === "" || localStorage.getItem('token') == null) {
            navigate("/");
        } else {
            getUser()
        }
    }, [])

    const getUser = () => {
        axios.get('/api/user', { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then((r) => {
                setUser(r.data)
            })
            .catch((e) => {
                console.log(e)
            });
    }

    const logoutAction = () => {
        axios.post('/api/logout', {}, { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } })
            .then((r) => {
                localStorage.setItem('token', "")
                navigate("/");
            })
            .catch((e) => {
                console.log(e)
            });
    }

    return (
        <>
            <Layout>
                <div className="row justify-content-md-center">
                    <div className="col-12">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="#">Dashboard</a>
                                Welcome, {user.name}!  <a href={CV} target="_Blank"> Curriculum Vitae </a>
                                <div className="d-flex">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a onClick={() => logoutAction()} className="nav-link " aria-current="page" href="#">
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        {/* <h4 className="text-center mt-5">
 </h4  > */}
                    </div>
                </div>
            {/* </Layout>
            <Layout> */}
                <div>

                    {/* <HeaderText/> */}

                    <hr></hr>
                    <div className='div-container'>
                        <div><p className='nm'>Email: sushmabahuguna@gmail.com
                            <br />Phone: 09811345938
                            <br />Address: 41, Swarn Apartment, Pitampura, New Delhi - 110034 </p>
                        </div>
                        <div className='pic'><img src={sbPic} alt=''></img></div>
                    </div>

                    <div className="navbar navbar-dark bg-dark ">
                        <ul class="nav nav-tabs">
                            <li>
                                <NavLink className="nav-link active" to="/" activeStyle={
                                    { color: 'red' }
                                }>Home</NavLink>
                            </li>

                            <li>
                                <NavLink className="nav-link" to="/components/experience" exact activeStyle={
                                    { color: 'magenta' }
                                }>Experience</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/components/current-job-role  " exact activeStyle={
                                    { color: 'magenta' }
                                }>Current Roles</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/components/achievements " exact activeStyle={
                                    { color: 'magenta' }
                                }>Academic Contribution</NavLink>
                            </li>

                            <li>
                                <NavLink className="nav-link" to="/components/publications-journals " exact activeStyle={
                                    { color: 'magenta' }
                                }>Publication</NavLink>
                            </li>

                            <li>
                                <NavLink className="nav-link" to="/components/activities-organized  " exact activeStyle={
                                    { color: 'magenta' }
                                }>Coordinator</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/components/fdp-stc " exact activeStyle={
                                    { color: 'magenta' }
                                }>FDP/STP/Workshops</NavLink>
                            </li>

                            <li>
                                <NavLink className="nav-link" to="/components/reviewer " exact activeStyle={
                                    { color: 'magenta' }
                                }>Reviewer/Guide</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/components/Academic" exact activeStyle={
                                    { color: 'red' }
                                }>Academic Details</NavLink>
                            </li>
                        </ul>
                    </div>


                    <Routes>
                        <Route path="/" element={<Home />}></Route>
                        <Route path="/components/academic" element={<AcademicDetails />}></Route>
                        <Route path="/components/experience" element={<EmploymentDetails />} />
                        <Route path="/components/achievements" element={<Achievements />}></Route>
                        <Route path="/components/publications-journals" element={<PublicationJournal />} />
                        <Route path="/components/current-job-role" element={<AdditionalResponsibilities />} />
                        <Route path="/components/activities-organized" element={<ActivitiesCoordinated />} />
                        <Route path="/components/fdp-stc" element={<FdpStc />} />
                        <Route path="/components/reviewer" element={<Reviewer />} />
                    </Routes>
                </div>
            </Layout>
            <img className='homeimg' src={Welcome} alt=''></img>
        </>
    );
}

export default Dashboard;