import '../App.css';
import Iit1 from '../assets/acrivities-coordinated/1iitb-certificate5-2-14.jpg'
import Iit2 from '../assets/acrivities-coordinated/2iitb-certificate12-11-13.jpg'
import Iit3 from '../assets/acrivities-coordinated/3iitb-certificate26-8-13.jpg'
import Iit4 from '../assets/acrivities-coordinated/4iitb-certificate24-7-13.jpg'
import Iit5 from '../assets/acrivities-coordinated/5iitb-certificate18-02-13.jpg'
import W1 from '../assets//acrivities-coordinated/Workshop16th-19thJune.jpg'
import W2 from '../assets/acrivities-coordinated/Workshop_JavaMicroservicesSpringBoot.jpg'
import W3 from '../assets/acrivities-coordinated/Workshop_Python.jpg'
import W4 from '../assets/acrivities-coordinated/Workshop_Angular.jpg'
export function ActivitiesCoordinated() {
    return (
        <div className="pd">
            <table>
                <tr><th>S. No.</th><th>Event</th><th>Date</th></tr>
                <tr><td>1</td><td><a href='https://www.youtube.com/watch?v=WGVJvz5nADQ' target='_Blank'> 3 days Workshop on Android Application Development [Speaker]  </a></td> <td><a href={W1}>16th 17th and 19th June 2021</a></td></tr>

                <tr><td>2</td> <td> <a href='https://bciit.ac.in/index.php?option=com_content&view=article&id=242:eventsoddsem2020&catid=49' target='_Blank'>Workshop on Creative Java Microservices with Spring Boot By Jatin Sehgal Sr. Software Designer with RBS </a></td><td><a href={W2}>29th Nov 2020</a></td></tr>
                <tr><td>3</td> <td><a href='https://bciit.ac.in/index.php?option=com_content&view=article&id=242:eventsoddsem2020&catid=49' target='_Blank'>Workshop on Object Oriented Programming in Python by Ms. Manvi Saxena, Full Stack Data Science Developer at A Global Commodity Trading Firm, Texas, USA </a></td><td><a href={W3}>8th Nov 2020</a></td></tr>

                <tr><td>4</td> <td><a href='https://bciit.ac.in/index.php?option=com_content&view=article&id=242:eventsoddsem2020&catid=49' target='_Blank'>Workshop on Angular by Akmal Rafi Ansari, Software Developer at Tecdune Services LLP</a></td> <td><a href={W4}>31st Oct 2020</a></td></tr>

                <tr><td>5</td><td>  <a href='https://bciit.ac.in/images/stories/Notice_Workshop_4_04_2015.pdf' target='_Blank'>
                    Workshop on PHP </a> </td><td>4th April 2015</td></tr>

                <tr><td>6</td><td> <a href='https://bciit.ac.in/images/stories/Notice_Seminar_25_03_2015.pdf' target='_Blank'>
                    Seminar on "Practical Aspects of Clouds" by Dr. Pragya Jain, Professor, IIT Delhi</a></td><td> 25th Mar 2015</td></tr>

                <tr><td>7</td><td> <a href='https://bciit.ac.in/images/stories/Notice_Workshop_17_3_2015.pdf' target='_Blank'>
                    Workshop on Android </a></td><td> 17th Mar 2015</td></tr>

                <tr><td>8</td><td>  <a href='https://bciit.ac.in/images/stories/Notice_Seminar_25_02_2015.pdf' target='_Blank'>Seminar on "Tricks and Techniques to Enhance Aptitude Skills" </a></td><td> 25th Feb 2015</td></tr>

                <tr><td>9</td><td> <a href='https://bciit.ac.in/images/stories/Notice_Smartchip_Award_Function.pdf' target='_Blank'>
                    Technical Session on Smartchip &  Smartchip Scholarship Award Function</a></td><td> 20th Feb 2015</td></tr>

                <tr><td>10</td><td> <a href='https://bciit.ac.in/images/stories/workshopnoticeJava.pdf' target='_Blank'>
                    workshop on “Java Programming” conducted by IIT Bombay </a></td><td>
                        15th Oct 2014</td></tr>

                <tr><td>11</td><td> <a href='https://bciit.ac.in/images/stories/SeminarNotice14thOct2014.pdf' target='_Blank'>Seminar on “Career Opportunities in Enterprise Cyber Security & Digital Forensics” by Mr. Vidit Baxi, Director, Licideus Training, Lucideus Tech Pvt. Ltd.</a></td><td> 14th Oct 2014</td></tr>

                <tr><td>12</td><td> <a href='https://bciit.ac.in/images/stories/workshopnoticeLinux.pdf' target='_Blank'>Workshop  on “LINUX”** conducted by IIT Bombay </a> </td><td>13th Oct 2014</td></tr>

                <tr><td>13</td><td> <a href='https://bciit.ac.in/images/stories/SeminarNotice27thAug2014.pdf' target='_Blank'> Seminar on Parallel Computing by Dr. Pragya Jain, Professor, IIT Delhi.</a></td> <td>27th Aug 2014</td></tr>

                    <tr><td>14</td><td> <a href={Iit3} target='_Blank'>Workshop [Spoken Tutorial - IIT Bombay] on PHP & MySQL  </a></td><td> 26/08/2013</td></tr>

                    <tr><td>14</td><td> <a href='https://bciit.ac.in/images/stories/SeminarNotice9Apr2014.pdf' target='_Blank'> Seminar on “Cloud and Virtualization” by Dr. Pragya Jain, Professor, IIT Delhi</a> </td><td>9th April 2014</td></tr>

                    <tr><td>15</td><td> <a href={Iit2} target='_Blank'>  Workshop [Spoken Tutorial - IIT Bombay]  on PHP & MySQL  </a></td><td> 23/03/2014</td></tr>

                    <tr><td>16</td><td>  <a href='https://bciit.ac.in/images/stories/SeminarNotice20Mar2014.pdf' target='_Blank'>Seminar  on Innovation & Promptness in Computer Technology by Dr. Rattan K Datta  </a></td><td> 20th Mar 2014</td> </tr>

                    <tr><td>17</td><td>  <a href='https://bciit.ac.in/images/stories/SeminarNotice3Mar2014.pdf' target='_Blank'>Seminar on Big Data by Dr. Savita Goel, Professor, IIT Delhi </a></td><td>3rd Mar 2014</td></tr>

                    <tr><td>18</td><td><a href={Iit1} target='_Blank'> Workshop [Spoken Tutorial - IIT Bombay]  on C & C++  </a></td><td> 05/02/2014</td></tr>

                    <tr><td>19</td><td>  <a href='https://bciit.ac.in/images/stories/SeminarNotice30Jan2014.pdf' target='_Blank' >Seminar on “SDP - Cyber Security Awareness” by Mr. Vidit Baxi, Director, Lucideus Training, Lucideus Tech Pvt Ltd</a> </td><td>30th Jan 2014 </td></tr>

                    <tr><td>20</td><td><a href={Iit2} target='_Blank'> Workshop [Spoken Tutorial - IIT Bombay]  on LINUX </a></td><td>  12/11/2013</td></tr>

                    <tr><td>21</td><td><a href={Iit4} target='_Blank'> Workshop [Spoken Tutorial - IIT Bombay]  on LINUX & Ubuntu </a></td><td> 24/07/2013</td></tr>

                    <tr><td>22</td><td> <a href={Iit5} target='_Blank'>  Workshop [Spoken Tutorial - IIT Bombay]  on PHP & MySQL </a></td><td>  18/02/2013</td></tr>

                    <tr><td>23</td><td>  Workshop on Hack O Mania </td><td>12/10/2012 to 13/10/2013</td></tr>

                    <tr><td>24</td><td> FDP on Mobile Architecture and Application Development for Symbian Based Devices </td><td>14/03/2011 to 16/03/2011</td></tr>

                </table>
                </div>);
}