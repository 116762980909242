

import '../App.css';

export function AdditionalResponsibilities() {
    return (
        <div className="pd">
            <table>
                <tr><th colSpan={3} className='p-heading'> Current Job Role (Additional Responsibilities Taken) </th></tr>
                <tr><td>1</td><td>Programme Coordinator (MCA) </td><td>Jan 2016 till date</td></tr>
                <tr><td>2</td><td>Website Coordinator (Internet/Intranet) </td><td>2010 till date</td></tr>
                <tr><td>3</td><td><a href='https://bciit.ac.in/images/stories/Committees/CommitteeAgainstSexualHarassment.pdf' target='_Blank'>Member (Chairman ) of Committee against Sexual Harassment </a></td><td>2013 till date</td></tr>
                <tr><td>4</td><td> <a href='https://bciit.ac.in/images/stories/Committees/GrievanceRedresssal.jpg' target='_Blank'>Member of Grievance Redressal Committee</a> </td><td>2012 till date</td></tr>
                <tr><td>5</td><td><a href='https://bciit.ac.in/images/stories/Committees/AntiRaggingCommittee.jpg' target='_Blank'>Member of Anti Ragging Committee</a> </td><td>2012 till date</td></tr>
                <tr><td>6</td><td><a href='https://bciit.ac.in/images/stories/Committees/AICTE-Counselling%20committee.pdf' target='_Blank'>Member of AICTE Directed Counseling Committee  </a></td><td>2018 till date</td></tr>
                <tr><td>7</td><td>Nodal Officer - National Scholarship Portal </td><td>Jan 2016 till date</td></tr>
               <tr><td>8</td><td>Nodal Officer - GGSIPU EWS Financial Assistance Scheme </td><td>Jan 2016 till date</td></tr>
               <tr><td>9</td><td>Nodal Officer - Edistrict Portal [ Post Matric Scholarship] </td><td>Jan 2016 till date</td></tr>
               <tr><td>10</td><td>Scholarship Coordinator- Private Sector Scholarship (Smartchip) </td><td>Jan 2014 till date</td></tr>
               <tr><td>11</td><td>BCIIT Fee Collection Committee  </td><td>2019 till date</td></tr>
                <tr><td>12</td><td>Examination Coordinator </td><td>2011 till 2014</td></tr>
                <tr><td>13</td><td>Executive Editor (E-Newsletter) </td><td>2013 till 2015</td></tr>
                <tr><td>14</td><td>Seminar/Workshop Coordinator </td><td>2013 till 2015</td></tr>
                <tr><td>15</td><td>Executive Editor (E-Magazine)  </td><td>2010 till 2015</td></tr>

            </table>
        </div>
    );
}
