import AD from '../assets/Experience/AcademicDetails.pdf';
import Thesis from '../assets/PhDSynposisThesis/ThesisSushma Bahuguna2011050203onESESIHS.pdf'

export function AcademicDetails(){
    return (
        <div className='outer-div'>
            <h1> Educational Qualification : <a href={AD} target='_Blank'>View Documents</a></h1>
            <table> 
                <tr><th>S.No.</th><th>Degree</th><th>Area of Specialization</th><th>	Percentage</th><th>University</th><th>Year of Completion </th></tr>

                <tr><td>1</td><td>  Ph.D.    </td>  <td><b>Thesis Title:</b>
                  <a href= {Thesis} target='_Blank'> Empirical Study of Emotion Based Speaker Identification For Hindi Speech </a></td><td> </td><td> Singhania University, Rajasthan</td><td>2015 </td></tr>
                <tr><td>2</td><td> M. Tech (IT)    </td> 	 <td>Information Technology </td><td> 71.46 %	</td><td>GGSIPU, Delhi </td><td> 2012</td></tr>
                <tr><td>3</td><td> MCA    </td>  <td>Computer Application </td><td>73.14% </td><td>Maharshi Dayanand University, Rohtak </td><td>2004 </td></tr>
                <tr><td>4</td><td>  MSc. (CS)  </td>  <td> Computer Science</td><td> 67.5%</td><td>Maharshi Dayanand University, Rohtak </td><td> 2002</td></tr>
                <tr><td>5</td><td> B. Ed.    </td>  <td> Social Studies</td><td>54% </td><td>Bharatidasan University, Tiruchrapally </td><td> 1995</td></tr>
                <tr><td>6</td><td>  B. Com     </td>  <td>Commerce </td><td>60% </td><td>Pune University, Maharashtra </td><td>1994 </td></tr>
                <tr><td>7</td><td>  Sr. Secondary(12th)   </td>  <td>Commerce </td><td> 68%</td><td>CBSE, Kendriya Vidyalaya, Pune </td><td> 1991</td></tr>
                <tr><td>8</td><td colSpan={5}>  Additional Qualifications  </td>   </tr>
                <tr><td>a</td><td> DCA  </td>  <td>Western India Computer Services </td><td>Pune </td><td>1st Division </td><td>1992</td></tr>
                <tr><td>b</td><td> PGDCA   </td>  <td>Bureau of Data Processing System </td><td> Hyderabad	</td><td>Ist Division </td><td>1995 </td></tr>
                 
            </table>
            
        </div>
    )
}