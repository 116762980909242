import React from "react";
import Best1 from '../assets/Conferences/best1-bcips.pdf';
import Best2 from '../assets/Conferences/Best2-BCIIT.jpg';
import Best3 from '../assets/Conferences/Best3-GDGU.pdf';

import C1 from '../assets/Conferences/c2-Certificate.jpg';
import C2 from '../assets/Conferences/c2-Certificate.jpg';
import C3 from '../assets/Conferences/c3-TIAS1.jpg';
import C4 from '../assets/Conferences/c4-tias2.jpg';
import C5 from '../assets/Conferences/c5-tias2.jpg';
import C6 from '../assets/Conferences/c6-usit1.jpg';
import C7 from '../assets/Conferences/c7-usit2.jpg';
import C8 from '../assets/Conferences/C8-gibs.jpg';
import C9 from '../assets/Conferences/c9-ITS.jpg';
import C10 from '../assets/Conferences/c9-ITS.jpg';
import C06 from '../assets/Conferences/Best3-GDGU.pdf';
import C05 from '../assets/Conferences/Best2-BCIIT.jpg';
import C04 from '../assets/Conferences/c04-SB-BCIIT-PaperPresentation-hw.jpg';
import C03 from '../assets/Conferences/c03-SB-PPC-vbes.pdf';
import C02 from '../assets/Conferences/c02-SB-PPC-MFD.pdf';
import C01 from '../assets/Conferences/c01-SB-PPC-PMMEDR.pdf';

export function PublicationJournal(){
return(<div  className='outer-div'>
  <h1> Publications Journals [National / International]</h1>
  <table>
    <tr><th>S.No.</th><th>Title</th><th>Journal</th><th> Year of Publication</th></tr>
    <tr><td>1</td><td><a href='https://iipseries.org/searching.php?type=author&key=Sushma%20Bahuguna#resultsection' target='_Blank'>
	An Overview Of Speech Emotion Recognition Using Machine Learning Techniques</a></td>
	<td>IIP Book Series,Futuristic Trends in Artificial Intelligence Volume 3 Book 6</td><td>Oct-2024</td></tr>

	<tr><td>2</td><td><a href='https://www.ijpd.co.in/papersv12n1/06.pdf' target='_Blank'>
	Variation in Speech Features of Different Emotions
	</a> </td><td>International Journal of Professional Development Vol.12, No.1</td><td>2023</td></tr>

	<tr><td>3</td><td><a href='https://bciit.ac.in/pdf/eJournal/Issue1/1.SB_Non-libc%20X8664%20disassembler%20using%20mnemonic%20instruction%20set_1-5.pdf'
	target='_Blank'>Non-libc X86/64 disassembler using mnemonic instruction set</a></td>
	<td>Journal Of Innovations in Computer Science and Trends in IT</td><td>2023</td></tr>


    <tr><td>4</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_12_ISSUE_3/IJCET_12_03_001.pdf' target='_Blank'>A Review of LSB-Based Audio Steganography Techniques</a></td><td>IJCET,Vol. 12, Issue 3</td><td>Oct-2021</td></tr>
<tr><td>5</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_10_ISSUE_4/IJCET_10_04_003.pdf' target='_Blank'>Audio Steganography  Technique using Circular Bit Replacement</a></td><td>IJCET,Vol. 10, Issue 4</td><td>July-Aug, 2019</td></tr>
<tr><td>6</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_9_ISSUE_4/IJCET_09_04_017.pdf' target='_Blank'>Evaluation of Nth Bit Replacement in Audio Steganography</a> </td><td>IJCET, Vol. 9, Issue 4</td><td>July-Aug, 2018</td></tr>
<tr><td>7</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_8_ISSUE_5/IJCET_08_05_001.pdf' target='_Blank'>Performance Evaluation of Speaker Identification System for Male Speaker During Adolescence</a></td><td>IJCET,Vol. 8, Issue 5</td><td>Sep-Oct 2017
</td></tr>
<tr><td>8</td><td><a href='https://ijcsi.org/articles/A-study-of-speech-emotion-and-speaker-identification-system-using-vq-and-gmm.ph' target='_Blank'>A Study of Speech Emotion and Speaker Identification System using VQ and GMM</a></td><td>IJCSI,Vol. 13, Issue 5</td><td>July 2016</td></tr>
<tr><td>9</td><td><a href='https://krishisanskriti.org/vol_image/17Dec201609121205%20%20%20%20%20%20%20Sushma%20Bahuguna(Computer)%20%20%20%20%20%20377-385.pdf' target='_Blank'>Review of Smartcard Attacks and Countermeasures</a></td><td> ACSIT Vol. 3, Issue 5</td><td>July-Sep, 2016</td></tr>
<tr><td>10</td><td><a href='https://www.academia.edu/119718747/Emotion_Based_Text_Independent_Speaker_Identification_Using_Gaussian_Mixture_Model_for_Hindi_Speech' target='_Blank'>Emotion Based Text Independent Speaker Identification using GMM for Hindi Speech</a></td><td>IIJC,Vol. 5 Issue 3
</td><td>July 2015</td></tr>
<tr><td>11</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJARET/VOLUME_5_ISSUE_8/IJARET_05_08_005.pdf' target='_Blank'>A Study Of Gender Specific Pitch Variation Pattern of Emotion Expression For Hindi Speech</a></td><td>IJARET,Vol. 5 Issue 8</td><td>Aug 2014</td></tr>
<tr><td>12</td><td><a href='https://iaeme.com/MasterAdmin/Journal_uploads/IJCET/VOLUME_4_ISSUE_6/IJCET_04_06_003.pdf' target='_Blank'>A Study Of Acoustic Features Pattern Of Emotion Expression For Hindi Speech</a></td><td>IJCET,Vol. 4 Issue 6</td><td>Dec-2013</td>
</tr>
<tr><td>13</td><td><a href='http://www.enggjournals.com/ijcse/doc/IJCSE13-05-07-017.pdf' target='_Blank'>Study of Speaker's Emotion Identification for Hindi Speech</a></td><td>IJCSE,
Vol. 5 Issue 7
</td><td>Jan 2013</td></tr>
<tr><td>14</td><td>Image Steganography with PCA and ICA</td><td>IIJC,Vol. 12, Issue 3</td><td>Jan 2012, Vol 2, Issue 3</td></tr>
<tr><td>15</td><td><a href='https://tiaspg.tecnia.in/wp-content/uploads/2019/09/vol5-no1_compressed.pdf' target='_Blank'>Semantic Web: Ontology Based Web</a></td><td>TJMS, Vol. 5</td><td>Sep 2010</td></tr>
  </table>
  <h1> Publications Conference [National / International]</h1>
			
  <table> <tr><th>S.No.</th><th>Title</th><th>Conference</th><th>Year of Publication</th>	</tr>

<tr><td>1</td><td><a href={C06} target='_Blank'>Performance of Random Forest for Flash Flood susceptibility in Indian Central Himalayan Region</a></td>
<td>6th International Conference GDGUICON on Driving Sustainability, Circularity and Development through Innovations in Management and Technology	GD Goenka | School of Management</td>
<td>4th - 5th April 2024</td></tr>

<tr><td>2</td><td><a href={C05} target='_Blank'>Performance of Machine Learning Algorithms for Multi-hazard Susceptibility Assessment in the Mountainous Regions: An Overview</a></td>
<td>1st International Conference on Advances in Computing, Communication and Intelligent Systems (ICACCIS)	Jointly Organized by Banarsidas Chandiwala Institute of Information Technology (BCIIT) and Universal Inovators, Delhi, India) </td>
<td>30th March 2024</td></tr>

<tr><td>3</td><td><a href={C04} target='_Blank'>Text Summarization for Short Answer Evaluation using NLP</a></td>
<td>1st International Conference on Advances in Computing, Communication and Intelligent Systems (ICACCIS)	Jointly Organized by Banarsidas Chandiwala Institute of Information Technology (BCIIT) and Universal Inovators, Delhi, India) </td>
<td>30th March 2024</td></tr>

<tr><td>4</td><td><a href={C03} target='_Blank'>Voice Based Email System – A Solution to Digital Communication for Individuals with Visual Impairments</a></td>
<td>11th National Conference on “Changing Global Landscape and New World of Work:Contours for Sustainable Business”-BCIPS </td><td>27th/28th June 2023</td></tr>

<tr><td>5</td><td><a href={C02} target='_Blank'>Masked Face Detection Using CNN – A Preventive Tool for Spread of Infectious Diseases</a></td>
<td>11th National Conference on “Changing Global Landscape and New World of Work:Contours for Sustainable Business”-BCIPS </td><td>27th/28th June 2023</td></tr>
<tr><td>6</td><td><a href={C01} target='_Blank'>PREPAREDNESS AND MITIGATION MEASURES FOR EARTHQUAKES IN DELHI REGION: AN OVERVIEW</a></td>
<td>11th National Conference on “Changing Global Landscape and New World of Work:Contours for Sustainable Business”-BCIPS </td><td>27th/28th June 2023</td></tr>
	
	<tr><td>7</td><td><a href={C1} target='_Blank'>A Review of Emotional Speech Recognition for Human-Computer Interaction</a></td><td>National Conference-MDU, Rohtak, ETSC-19 </td><td>Mar-2019</td></tr>
	
	<tr><td>8</td><td><a href={C2} target='_Blank'>Review of Smartcard Attacks and Counter Measures</a></td><td>International Conference on IREE, 	Krishi Sanskriti Publications, (ECIM-2016)</td><td>Jun-2016</td></tr>
	
	<tr><td>9</td><td><a href={C3} target='_Blank'>Emotion based Speaker Identification for Hindi Speech</a></td><td>National Conference, 	TIAS</td><td>Apr-2011</td></tr>
	
	<tr><td>10</td><td><a href={C4} target='_Blank'>Security framework for e- governance</a></td><td>National Conference, 	RDIAS</td><td>Apr-2010</td></tr>
	
	<tr><td>11</td><td><a href={C5} target='_Blank'>Cloud computing: A new era of IT opportunity and challenges</a></td><td>National Conference, 	TIAS</td><td>Mar-2010</td></tr>
	
	<tr><td>12</td><td><a href={C6} target='_Blank'>Cloud computing- Future of mobile</a></td><td>	National Conference, 	USMS Jointly with IETE Delhi, CSI and IEEE Delhi	</td><td>Mar-10</td></tr>
	
	<tr><td>13</td><td><a href={C7} target='_Blank'>Neural network technique for data mining</a></td><td>	National Conference, 	USMS Jointly with IETE Delhi, CSI and IEEE Delhi	</td><td>Mar-10</td></tr>
	
	<tr><td>14</td><td><a href={C8} target='_Blank'>Mining Structured Data from Unstructured Data Sources</a></td><td>	National Conference, 	GIBS, New Delhi </td><td>Feb-10</td></tr>
	
	<tr><td>15</td><td><a href={C9} target='_Blank'>Comparative Study of data, text and web mining</a></td><td>	National Conference, 	I.T.S. Management & IT Institute, Gaziabad	</td><td>Nov-09</td></tr>
</table>
</div>);
}