

import Icccn from '../assets/ReviewCommittee/icccn-2018.pdf';
import ICCIDA from '../assets/ReviewCommittee/ICCIDA-2018.jpg';
import Supervisor from '../assets/Co-Guide-SandeepJain.pdf'
import Synopsis from '../assets/PhDSynposisThesis/synopsis31-05-2018SandeepJain.pdf'
import '../App.css';
export function Reviewer() {
    return (<div className='outer-div'>
        <h2>
            Reviewer:
        </h2>
        <table>
          <h3>  <tr><td> <a href='https://books.google.co.in/books?id=rVptDwAAQBAJ&pg=PR13&lpg=PR13#v=onepage&q&f=false'>  2nd International Conference on Communication, Computing and Networking
                ICCCN 2018, NITTTR Chandigarh, India </a></td><td>Certificate:  <a href={Icccn}>View </a></td></tr>
            <tr><td>   <a href='https://drive.google.com/file/d/1_BOijkHmR8fRrsuNT8O_YPzFNGk_nL8M/view'>International Conference on Computational Intelligence and Data Analytics,
                ICCIDA, Gandhi Institute of Technology, Odhisa, Bhubaneshwar, India </a></td><td>Certificate:  <a href={ICCIDA}>View </a></td></tr>
                </h3>
        </table>

        <hr />
        <h2>
            Ph. D. Guided as Sole/Joint Supervisor (Yes/No)
        </h2>
        <h3>
            <a href={Supervisor}>Co-Guide : of Mr Sandeep Jain  Pursuing Ph. D. from Singhania University, Rajasthan	Pursuing </a>
            <br></br>
            <a href={Synopsis} target='_blank'>
            Title: THE STUDY AND IMPLEMENTATION OF EMPIRICALLY DERIVED PARAMETERS OF SPEECH EMOTION ON TEXT TO SPEECH SYNTHESIZER SYSTEM FOR HINDI SPEECH </a>
        </h3>

    </div>
    );
}
