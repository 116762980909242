
import F1 from '../assets/AICTE/1AICTE-FDP-UHV-11-15April2022.pdf';
import F2 from '../assets/AICTE/2AICTE-FDPIoT&Cyber Security06-09-2021.pdf';
import F3 from '../assets/AICTE/3AICTE-FDP-ArtificialIntelligence-and-itsApplications27-09-2021.pdf';
import F4 from '../assets/AICTE/4AICTE-FDP-ArtificialIntelligence-and-Applications2021-06-07.pdf'
import F5 from '../assets/AICTE/5ATAL-FDP-Certificate-08-06-2020.pdf'
import F10 from '../assets/AICTE/10aicte-nitttr-stc-bvicam.pdf'
import FCert from '../assets/AICTE/fdp-stc.pdf';
import F21 from '../assets/AICTE/6AICTE-FDP-MLUsingPython-BCIVAM.pdf';
import F31 from '../assets/AICTE/FDP 1 CCP BCIPS.pdf';
import F11 from '../assets/AICTE/FDP 3 AIU-GGSIPU-AADC-FDP.pdf';
import WA from '../assets/acrivities-coordinated/workshopCertificates.pdf';
import '../App.css';

export function FdpStc() {
    return (<div  className='outer-div'>
        <table>
            <tr><th>S.No.</th><th>Programme</th><th>Event</th><th>Duration</th><th>Organized by</th><th>Month and Year</th></tr>

            <tr><td>1</td><td> <a href={F31} target='_Blank'>  Role of Artificial Intelligence in Viksit Bharat@2047</a>  </td>
            <td>FDP</td><td>1 Week</td><td>Association of Indian Universities & GGSIP University Academic and Administrative Development Centre (AADC)</td><td>11/03/2024 to 15/03/2024</td></tr>

            <tr><td>2</td><td> <a href={F21} target='_Blank'>  Machine Learning using Python Programming</a>  </td>
            <td>FDP</td><td>1 Week</td><td>AICTE ATAL Academy-BHARATI VIDYAPEETH'S INSTITUTE OF COMPUTER APPLICATIONS AND MANAGEMENT</td><td>06/11/2023 to 11/11/2023</td></tr>

            <tr><td>3</td><td> <a href={F11} target='_Blank'>  Advance Data Analysis for Scape Development</a>  </td>
            <td>FDP</td><td>1 Week</td><td>BCIPS-Dwarka</td><td>27-07-2023</td></tr>
            		
           
           



            <tr><td>4</td><td> <a href={F1} target='_Blank'>  Inculcating Universal Human Values in Technical Education</a>  </td>
            <td>FDP</td><td>1 Week</td><td>All India Council for Technical Education(AICTE)</td><td>11/04/2022 to 15/04/2022</td></tr>
            		
            <tr><td>5</td><td> <a href={F2} target='_Blank'>  IoT & Cyber Security</a>  </td>
            <td>FDP</td><td>1 Week</td><td>AICTE ATAL Academy-Institute of Engineering & Management.</td><td>06/09/2021 to 10/09/2021</td></tr>

            <tr><td>6</td><td> <a href={F3} target='_Blank'>  Internet of Things (IoT): Architecture & System Level Design</a>  </td>
            <td>FDP</td><td>1 Week</td><td>AICTE ATAL Academy-USIC&T, GGSIPU</td><td>27/09/2021 to 01/10/2021</td></tr>

            <tr><td>7</td><td> <a href={F4} target='_Blank'>  Artificial Intelligence and its Application</a>  </td>
            <td>FDP</td><td>1 Week</td><td>AICTE ATAL Academy-Government college of engineering, Nagpur</td><td>2021-06-07 to 2021-06-11</td></tr>

            <tr><td>8</td><td> <a href={F5} target='_Blank'>   Artificial Intelligence</a> Certificate:  <a href=''>View </a></td>
            <td>FDP</td><td>1 Week</td><td>AICTE Training And Learning (ATAL) Academy IIITD</td><td>08/06/2020 to 12/06/2020</td></tr>
		
            <tr><td>9</td><td>  Python  </td>
            <td>FDP</td><td>1 Week</td><td>ACM@USICT Chapter organized and CADDESK sponsored</td><td>29/06/2020 to 05/07/2020</td></tr>


            <tr><td>10</td><td> <a href={FCert} target='_Blank'>  Emerging Research Trends in Computer Science and IT</a>  </td>
            <td>FDP</td><td>2 Week</td><td>IEEE Delhi Section & IIPC, AICTE, BVICAM</td><td>13/05/2019 to 25/05/2019</td></tr>

            <tr><td>11</td><td> <a href={FCert} target='_Blank'>  Data Science and Machine Learning Applications in IT & Management Domains</a>  </td>
            <td>FDP</td><td>1 Week</td><td>DIAS, New Delhi</td><td>25/05/2018 to 30/05/2018</td></tr>



            <tr><td>12</td><td> <a href={FCert} target='_Blank'>  Information Security and Digital Forensics ISDF-2017</a>  </td>
            <td>STC</td><td>1 Week</td><td>NSIT, New Delhi</td><td>05/06/2017 to 09/06/2017</td></tr>


            <tr><td>13</td><td> <a href={F10}>  Vulnerability Assessment of Networks and web Applications</a>  </td>
            <td>STC</td><td>1 Week</td><td>NITTTR - AICTE Recognized-BVICAM, New Delhi</td><td>29/02/2016 to 5/03/2016</td></tr>


            <tr><td>14</td><td> <a href={FCert} target='_Blank'>  Research Methodology using SPSS</a>  </td>
            <td>FDP</td><td>2 Days</td><td>BCIPS, New Delhi</td><td>01/08/2015 to 2/08/2015</td></tr>

            <tr><td>15</td><td> <a href={FCert} target='_Blank'>   Entrepreneurship development</a>  </td>
            <td>FDP</td><td>1 Week</td><td>BCIPS, New Delhi</td><td>02/12/2013 to 13/12/2013</td></tr>

            
            <tr><td>16</td><td> <a href={FCert} target='_Blank'>  ICT based Training using Spoken Tutorial</a>  </td>
            <td>FDP</td><td>1 day</td><td>BPIT, New Delhi</td><td>29/11/2013</td></tr>


            <tr><td>17</td><td>Research Paper Writing <a href={FCert} target='_Blank'>  </a>  </td>
            <td>FDP</td><td>1 day</td><td>GNIM. New Delhi</td><td>7/01/2012</td></tr>


            <tr><td>18</td><td> <a href={FCert} target='_Blank'>  Mobile Architecture and Application Development</a>  </td>
            <td>FDP</td><td>3 daysk</td><td>BCIIT, Kalkaji</td><td>14/03/2011 to 16/03/2011</td></tr>


            <tr><td>19</td><td> <a href={FCert} target='_Blank'>  IBM Certified Associate Developer Rational Application Developer for WebSphere Software V6.0</a>  </td>
            <td>Training</td><td>1 day</td><td>IBM</td><td>17/08/2010</td></tr>


            <tr><td>20</td><td> <a href={FCert} target='_Blank'>  IBM Certified Database Associate DB2 9 Fundamentals</a> </td>
            <td>Training</td><td>1 day</td><td>IBM</td><td>16/11/2009</td></tr>
        
            <tr><td>21</td><td> <a href={FCert} target='_Blank'>  11i Oracle Receivables Fundamentals</a>  </td>
            <td>Training</td><td>1 Week</td><td>Oracle University, New Delhi</td><td>16/07/2007 to 20/07/2007</td></tr>
        

            	
            <tr><td>22</td><td> <a href={FCert} target='_Blank'>  11i eBusiness Suite Essentials for Implementers</a>  </td>
            <td>Training</td><td>1 Week</td><td>Oracle University, New Delhi</td><td>31/05/2007 to 04/07/2007</td></tr>

            <tr><td>23</td><td> <a href={FCert} target='_Blank'>  Introduction to Oracle9i -SQL</a>  </td>
            <td>Training</td><td>1 Week</td><td>NIIT Connaught Place.</td><td>30/04/2007 to 11/05/2007</td></tr>

        
        </table>

        <br></br>
            <h1> Workshops Attended : <a href={WA}>View Certificates</a></h1>
            <table> 
                <tr><th>S.No.</th><th>Conducted By</th><th>Topic</th><th>	Date</th></tr>

                <tr><td>1</td><td> Network Bulls-at BCIIT    </td>  <td><b>Network Implementation</b>
                </td><td>11th Aug 2017 </td></tr>
                <tr><td>2</td><td> DUCAT -at BCIIT   </td>  <td><b>Web Designing using HTML5</b>
                </td><td>7th Oct 2016 </td></tr>
                <tr><td>3</td><td> DUCAT -at BCIIT   </td>  <td><b>Android Application Development</b>
                </td><td>17th Sep 2016 </td></tr>
                <tr><td>4</td><td> DUCAT -at BCIIT   </td>  <td><b>Android </b>
                </td><td>28th Aug 2015 </td></tr>
                <tr><td>5</td><td> DUCAT -at BCIIT   </td>  <td><b>PHP</b>
                </td><td>4th April 2015 </td></tr>
                <tr><td>6</td><td> DUCAT -at BCIIT   </td>  <td><b>Android </b>
                </td><td>17th Mar 2015 </td></tr>
                <tr><td>7</td><td> DUCAT -at BCIIT   </td>  <td><b>Advanced Java </b>
                </td><td>6th Sep 2014 </td></tr>
                <tr><td>8</td><td> Delhi Technological University - (DTU)   </td>  <td><b>Recent Advances in Software Engineering </b>
                </td><td>20th-21st April 2012 </td></tr>
                <tr><td>9</td><td> i3idya Technologies  </td>  <td><b>Hack O Mania 1.0 </b>
                </td><td>12th-13th Oct 2012</td></tr>
                <tr><td>10</td><td> TIAS  </td>  <td><b>Leverating Technologies using ERP and Open Source Technology</b>
                </td><td>25th Mar 2011 </td></tr>
                <tr><td>11</td><td> Delhi Technological University - (DTU)  </td>  <td><b>Recent Trends in Software Testing </b>
                </td><td>17th Sep 2010 </td></tr>
                <tr><td>12</td><td>GGSIPU & BCIPS  </td>  <td><b>ISO Awareness</b>
                </td><td>22nd Mar 2010 </td></tr>
            </table>
       

    </div>
    );
}


	