import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Dashboard from "./pages/Dashboard"
import { PublicationJournal } from './components/publications-journals';
import { Achievements } from './components/achievements';
import { EmploymentDetails } from './components/experience';
import { Reviewer } from './components/reviewer';
import { FdpStc } from './components/fdp-stc';
import { AcademicDetails } from './components/academic';
import { AdditionalResponsibilities } from './components/current-job-role';
import Welcome from './assets/HomeImage.png'
import { ActivitiesCoordinated } from './components/activities-organized';
function Home() {
  return (<img className='homeimg' src={Welcome} alt=''></img>);
}
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/*" element={<Dashboard />} />
          {/* <Route index element={<Home />}></Route>
          <Route path="academic" element={<AcademicDetails />}></Route>
          <Route path="experience" element={<EmploymentDetails />} />
          <Route path="achievements" element={<Achievements />}></Route>
          <Route path="publications-journals" element={<PublicationJournal />} />
          <Route path="current-job-role" element={<AdditionalResponsibilities />} />
          <Route path="activities-organized" element={<ActivitiesCoordinated />} />
          <Route path="fdp-stc" element={<FdpStc />} />
          <Route path="reviewer" element={<Reviewer />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
