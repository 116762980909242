
import Bciit from '../assets/Experience/bciit.pdf';
import WebUniv from '../assets/Experience/webuniv.jpg';
import Wizkid from '../assets/Experience/wizkid.jpg';
import Tias from '../assets/Experience/TIASLetter.jpg';
import '../App.css'
import BDPS from '../assets/Experience/BDPS.jpg'


export function EmploymentDetails()
{
    return(
        <div  className='outer-div'>
            <h1 className='p-heading'> Total Experience: 24+ Years  </h1>
            <table>
                <tr> <th>S.No </th><th>Institute Name</th><th>University</th><th>From Date</th><th>To Date</th><th>Duration</th></tr>
                <tr><td>1</td><td><a href={Bciit} target='_Blank'>Banarsidas Chandiwala Institute of Information Technology (BCIIT), Kalkaji, New Delhi</a></td><td> GGSIPU, Delhi </td><td>Sep-2008</td><td>Till Date</td><td>13Yrs, 9Mths </td> </tr>
                <tr><td>2</td><td><a href={Tias} target='_Blank'>Tecnia Institute of Advanced Studies(TIAS), Rohini, New Delhi</a></td><td> GGSIPU, Delhi </td><td>Mar-2008</td><td>Sep-2008</td><td>7Mths </td> </tr>
                <tr><td>3</td><td><a href={WebUniv} target='_Blank'>WebUniv Infotech, Punjabi Bagh New Delhi</a></td><td> MDU, Rohtak </td><td>Jan’ 00 </td><td>Feb’ 08 </td><td>8 Yrs, 2 mths</td> </tr>
                <tr><td>3</td><td><a href={Wizkid} target='_Blank'>Wizkid, New Delhi</a></td><td> ISCT, Delhi </td><td>Sep’ 96</td><td>Jan- 2000</td><td>	3 Yrs, 3 mths </td> </tr>
                <tr><td>4</td><td><a href={BDPS} target='_Blank'>Bureau of Data Processing Systems(BDPS), Secunderabad </a></td><td> BDPS </td><td>Jul-1995</td><td>Jun-1996</td><td> 1Yr </td> </tr>

            </table>
        </div>
    );
}

